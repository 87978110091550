import {
  createRenderer,
  type MergedResolvers,
  type RendererOptions,
} from '~/storyblok/utils/richtext/renderer'
import { defaultResolvers } from '~/storyblok/utils/richtext/resolvers'

const resolvers: MergedResolvers = {
  ...defaultResolvers,
  components: {},
}

export function useRichTextRenderer(
  options?: Partial<RendererOptions>,
): ReturnType<typeof createRenderer> {
  return createRenderer({ resolvers, ...options })
}
